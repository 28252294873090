import React from 'react';
import styled from 'styled-components';
import Imgix from 'shared/components/imgix';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ImageBanner } from 'shared/components/image-banner';
import { mediaQueries } from 'shared/styles';
import { ImgixDefaultSettings } from 'shared/constants';
import { tracker } from 'src/utils/analytics';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { BannerImageWrapper } from './banner-image-wrapper';

export type Banner = {
  _id: string;
  image: string;
  mobileImage: string;
  alt?: string | null;
  link?: string | null;
  openInNewTab?: boolean | null;
  position: number;
};

export type MenuImageBannerProps = {
  banners: Banner[] | null;
};

export const TEST_ID_MENU_IMAGE_BANNER = 'menu-image-banner';
export const TEST_ID_SCROLL_ITEM_CONTAINER = 'scroll-item-container';

export function MenuImageBanner({ banners }: MenuImageBannerProps): JSX.Element {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down(`sm`));
  const { dispensary } = useDispensary();

  if (!banners) {
    return <></>;
  }

  const carouselOptions = {
    draggable: banners.length > 1,
    loop: true,
    startIndex: 0,
    slidesToScroll: 1,
    banners,
  };

  return (
    <ImageBanner carouselOptions={carouselOptions}>
      <MainImageScrollContainer data-testid={TEST_ID_MENU_IMAGE_BANNER}>
        {banners.map((banner, index) => {
          const target =
            banner.openInNewTab === null || typeof banner.openInNewTab === 'undefined' || banner.openInNewTab
              ? '_blank'
              : '_top';
          return (
            <ScrollItem
              key={banner._id}
              data-testid={TEST_ID_SCROLL_ITEM_CONTAINER}
              isSingleBanner={banners.length === 1}
            >
              <BannerImageWrapper
                linkUrl={banner.link}
                rel={target === '_blank' ? 'noopener noreferrer' : null}
                target={target}
                onClick={() =>
                  tracker.imageBannerClicked({ dispensaryId: dispensary.id, dispensaryName: dispensary.name })
                }
              >
                <MainImage
                  type='img'
                  imgixParams={{ 
                    ...ImgixDefaultSettings, 
                    crop: [`edges`], 
                    ar: (isMobile ? '2.22:1' : '3:1') 
                  }}
                  htmlAttributes={{ alt: banner.alt, loading: 'lazy', decoding: 'async' }}
                  src={isMobile ? banner.mobileImage : banner.image}
                  className={`product-image-${index}`}
                />
              </BannerImageWrapper>
            </ScrollItem>
          );
        })}
      </MainImageScrollContainer>
    </ImageBanner>
  );
}

const MainImageScrollContainer = styled.div`
  display: flex;
`;

const ScrollItem = styled.div`
  min-width: 100%;
  display: flex;
  justify-content: center;

  @media ${mediaQueries.largePhone} {
    margin-right: ${({ isSingleBanner }) => (isSingleBanner ? '0' : '-44px')};
  }
`;

const MainImage = styled(Imgix)`
  aspect-ratio: 3/1 auto;
  border-radius: ${({ theme }) => theme.customized.radius.lg};
  display: block;
  object-fit: contain;
  width: 100%;

  @media ${mediaQueries.largePhone} {
    aspect-ratio: 2.22/1 auto;
  }
`;
